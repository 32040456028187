<script lang="ts" setup>
import { onClickOutside, unrefElement, useEventListener } from '@vueuse/core'
import { Dropdown } from 'bootstrap'
import BsButton from '@/src/basics/BsButton.vue'
import type { Variant } from '@/src/types/bootstrap'

withDefaults(
  defineProps<{
    variant: Variant | 'transparent' | 'link'
    size?: 'sm'
    right?: boolean
    noCaret?: boolean
    buttonClass?: string
    menuClass?: string
    tabindex?: number
  }>(),
  {
    variant: 'transparent',
  },
)

const emit = defineEmits<{
  shown: []
}>()

const elementRef = ref<HTMLDivElement>()
const buttonRef = ref<InstanceType<typeof BsButton>>()
const menuRef = ref<HTMLUListElement>()

// NOTE: bootstrap@5.2.3デフォルトだと他のドロップダウンを開いた場合に閉じないので自前制御を追加している
onClickOutside(buttonRef, (event) => {
  if (menuRef.value?.contains(event.target as HTMLElement)) {
    return
  }

  const element = unrefElement(buttonRef)
  if (!element) return

  Dropdown.getInstance(element)?.hide()
})

useEventListener(elementRef, 'shown.bs.dropdown', () => {
  emit('shown')
})
</script>

<template>
  <!-- NOTE: position-staticは親要素がoverflow: hiddenでも問題なく表示されるようにするため -->
  <div ref="elementRef" class="dropdown position-static">
    <BsButton
      ref="buttonRef"
      data-bs-toggle="dropdown"
      :variant="variant"
      :size="size"
      :tabindex="tabindex"
      :class="[
        buttonClass,
        {
          'dropdown-toggle': !noCaret,
        },
      ]"
      @click.stop=""
    >
      <slot name="button-content" />
    </BsButton>
    <ul ref="menuRef" class="dropdown-menu" :class="[menuClass, { 'dropdown-menu-end': right }]">
      <slot />
    </ul>
  </div>
</template>
